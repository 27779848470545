import type { SvpAsset } from '@vgtv/api-client/lib/svp_asset';

export function getLastPathnameComponent(url: string): string | undefined {
  const splitUrl = url.split('/');
  return splitUrl[splitUrl.length - 1];
}

/**
 * Checks if slug is time string in format XXhYYmZZs
 * Ex: 01h12m01s
 */
export function isTimeString(slug = ''): boolean {
  return /^([0-9]{2}[hms]){3}$/.test(slug);
}

/**
 * Checks if value is start time string
 * Ex: 10s
 */
export const isStartTimeString = (value: string): boolean => {
  return /^\d+(?:|s)$/.test(value);
};

const pad = (value: number) => {
  return String(value).padStart(2, '0');
};

type TimeFormat = `${string}h${string}m${string}s`;

/**
 * Formats seconds into XXhYYmZZs format
 */
export const formatTime = (timeInSeconds: number): TimeFormat => {
  if (isNaN(timeInSeconds) || !isFinite(timeInSeconds)) {
    return '00h00m00s';
  }

  const hours = Math.floor(timeInSeconds / (60 * 60));
  const minutes = Math.floor(timeInSeconds / 60) % 60;
  const seconds = Math.floor(timeInSeconds) % 60;

  return `${pad(hours)}h${pad(minutes)}m${pad(seconds)}s`;
};

/**
 * Checks if slug is chapter id made of one or more digits
 * Ex: 12345
 */
export function isChapterId(slug = ''): boolean {
  return /^\d+$/.test(slug);
}

export const patchPlayerAsset = (asset: SvpAsset) => {
  // passing vendor manually is required to fix tracking for mixed vendors, e.g. playing
  // assets from brandstudio newsroom using player initialized with vgtv provider
  return { ...asset, vendor: asset.provider };
};
