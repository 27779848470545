import type {
  SvpCategory,
  SvpCategoryAdditional,
} from '@vgtv/api-client/lib/svp_category';
import { Provider } from '@vgtv/api-client';
import type { IMBOImageUrl } from '@vgtv/api-client';

import {
  RESTRICTED_CATEGORIES_AB,
  RESTRICTED_CATEGORIES_VGTV,
} from '../../consts.js';
import { formatRouteParam } from '../../utils/string.js';
import type { Vendor } from '../../types.js';
import { getAssetFlightTimesStart } from '../../player/playerAssetHelpers.js';

import type { SvpEpisode } from './assetHelpers.js';

function getCategoryMainImage(
  category: SvpCategoryAdditional
): IMBOImageUrl | undefined {
  const { additional } = category;

  return additional.image ?? undefined;
}

function getCategoryBackgroundImage(
  category: SvpCategoryAdditional
): IMBOImageUrl | undefined {
  return (
    category.additional?.images?.splashBackground ??
    getCategoryMainImage(category)
  );
}

function getCategoryVerticalImage(
  category: SvpCategoryAdditional
): IMBOImageUrl | undefined {
  return (
    getCategoryMetadata(category).verticalPoster?.value ||
    getCategoryMainImage(category)
  );
}

function getCategoryLogoImage(
  category: SvpCategoryAdditional
): IMBOImageUrl | undefined {
  const { additional } = category;

  return additional.images ? additional.images.splashLogo : undefined;
}

function getCategoryIconImage(
  category: SvpCategoryAdditional
): IMBOImageUrl | undefined {
  const { additional } = category;

  return additional.images ? additional.images.icon : undefined;
}

const getCategoryShortPath = (category: SvpCategoryAdditional) =>
  (
    category.additional.shortPath ?? formatRouteParam(category.title)
  ).toLowerCase();

const emptyCategoryMetadata = Object.freeze(
  {} as Exclude<SvpCategory['additional'], null>['metadata']
);
function getCategoryMetadata(category: {
  additional: Pick<SvpCategoryAdditional['additional'], 'metadata'>;
}) {
  return category.additional?.metadata ?? emptyCategoryMetadata;
}

function getCategoryPromoUrl(category: SvpCategoryAdditional) {
  return getCategoryMetadata(category).promoUrl?.value;
}

function isCategoryArchived(category: SvpCategory) {
  return category.showCategory === false;
}

function isCategoryRestricted(vendor: Vendor, category: SvpCategory): boolean {
  if (vendor === 'brandstudio') {
    return category.additional?.metadata.showOnVGTV?.value !== 'true';
  }

  const categories =
    vendor === Provider.VGTV
      ? RESTRICTED_CATEGORIES_VGTV
      : RESTRICTED_CATEGORIES_AB;

  return categories.includes(category.id) || isCategoryArchived(category);
}

const FOURTEEN_DAYS_IN_SECONDS = 14 * 24 * 60 * 60;

export const isNewlyReleasedSeason = (
  episode: SvpEpisode,
  relativeTo?: number
) => {
  const flightTimesStart = getAssetFlightTimesStart(episode);

  if (!flightTimesStart) {
    return false;
  }

  const nowUnixTimestamp = relativeTo || Date.now() / 1000;

  return flightTimesStart + FOURTEEN_DAYS_IN_SECONDS > nowUnixTimestamp;
};

export {
  getCategoryMetadata,
  getCategoryMainImage,
  getCategoryIconImage,
  getCategoryBackgroundImage,
  getCategoryLogoImage,
  getCategoryShortPath,
  getCategoryPromoUrl,
  isCategoryArchived,
  isCategoryRestricted,
  getCategoryVerticalImage,
};
