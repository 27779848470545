// eslint-disable-next-line import/no-unresolved
import type { Player } from '@schibsted-svp/web-player';
import type { SvpAssetAdditional } from '@vgtv/api-client/lib/svp_asset';

import { isMobile, isIOS } from '../utils/device';
import { getQueryStringParams } from '../utils/getQueryStringParams';
import { localStorageHelper } from '../helpers/storage/index';
import { cloneDeep } from '../utils/cloneDeep';

import {
  isAssetPaywalled,
  isAssetUpcoming,
  isDocumentary,
  willAssetHaveDisplayAd,
} from './playerAssetHelpers';
import { playerContainer } from './playerContainer';
import {
  getLastPathnameComponent,
  isChapterId,
  isStartTimeString,
  formatTime,
  isTimeString,
  patchPlayerAsset,
} from './playerHelpers';
import { SITE_CONFIG, SITE_OPTIONS, PLAYER_DEFAULTS } from './configuration';

let resolvePlayer: (value: Player | PromiseLike<Player>) => void;
let playerPromise: Promise<Player> = new Promise((resolve) => {
  resolvePlayer = resolve;
});

/**
 * Resolves with an initialized instance of SVP Player.
 */
function getPlayer(): Promise<Player> {
  return playerPromise;
}

/**
 * When the player is initialized, we need to decide whether to trigger autoplay.
 * Usually autoplay is disabled for documentaries on mobile, paywalled content and upcoming live streams.
 * Assets with disabled autoplay can be preloaded but they won't start playing in the background
 * Please note that autoplay might be blocked by the browser based on the Media Engagement Index
 * @see https://www.chromium.org/audio-video/autoplay
 */
function shouldAutoplay(asset?: SvpAssetAdditional) {
  if (!asset) {
    /**
     * This is needed in iOS for `.playNext()` to work properly - autoplay next asset.
     * Theory is that mock asset doesn't contain audio tracks, so when video element gains an audio track it pauses,
     * which then requires additional user click to start playback (ref https://developer.apple.com/documentation/webkit/delivering_video_content_for_safari#3030251).
     */
    return isIOS;
  }

  if (isDocumentary(asset) && isMobile) {
    return false;
  }

  if (isAssetPaywalled(asset) || isAssetUpcoming(asset)) {
    return false;
  }

  return true;
}

/**
 * Creates a new instance of the SVP Player setup for the current provider.
 */
function createPlayer(
  asset: SvpAssetAdditional | undefined,
  { time = 0, autoplay }: { time?: number; autoplay?: boolean } = {}
): Player {
  const playerConfig = window.SVP!.getPlayerConfig(SITE_OPTIONS, {
    ...SITE_CONFIG,
    id: asset ? patchPlayerAsset(asset) : asset,
    autoplay,
  });

  const currentUrl = window.location.href;

  if (time) {
    playerConfig.time = formatTime(time);
  } else {
    const lastElement = getLastPathnameComponent(currentUrl);
    if (lastElement) {
      if (isTimeString(lastElement)) {
        playerConfig.time = lastElement;
      } else if (isChapterId(lastElement)) {
        playerConfig.chapter = parseInt(lastElement);
      }
    }

    const { t: startTime } = getQueryStringParams<'t'>();

    if (startTime && isStartTimeString(startTime)) {
      playerConfig.time = formatTime(parseInt(startTime));
    }
  }

  if (playerConfig.time) {
    delete playerConfig.chapter;
  }

  return new window.SVP!.Player(playerConfig);
}

/**
 * Awaits for SVP Player SDK to be loaded and initializes a player instance.
 */
function initializePlayer(
  asset?: SvpAssetAdditional,
  options: { time?: number; autoplay?: boolean } = {}
): Promise<Player> {
  async function initializeAndResolve() {
    const { time, autoplay } = options;
    localStorageHelper.setItem('jwplayer.mute', 'false');

    window.SVP!.Player.defaults = PLAYER_DEFAULTS;

    const player = createPlayer(cloneDeep(asset), {
      time,
      autoplay: autoplay ?? shouldAutoplay(asset),
    });

    const playerErrorHandler = () => {
      resolvePlayer(player);
      document.dispatchEvent(new CustomEvent('playerReady'));
    };

    // show the player before the app starts initializing
    if (asset) {
      if (
        !isDocumentary(asset) &&
        !isAssetPaywalled(asset) &&
        !isAssetUpcoming(asset)
      ) {
        playerContainer.showOnRender({
          offsetForDisplayAd: willAssetHaveDisplayAd(asset),
        });
      }
    }

    player.once('ready', () => {
      player.off('error', playerErrorHandler);
      resolvePlayer(player);
      document.dispatchEvent(new CustomEvent('playerReady'));
    });

    // player might initialize with an error (token secured, geoblock, etc.), it'll still be usable though
    player.once('error', playerErrorHandler);
  }

  if (window.SVP) {
    // player SDK is already loaded (most likely a cached disk read)
    initializeAndResolve();
  } else {
    window.addEventListener('onSvpPlayerReady', initializeAndResolve);
  }

  return playerPromise;
}

const recreatePlayer = async (
  asset: SvpAssetAdditional,
  options?: { time?: number; autoplay?: boolean }
) => {
  try {
    const player = await playerPromise;
    if (player) {
      player.remove();
    }

    playerPromise = new Promise((resolve) => {
      resolvePlayer = resolve;
      const item = cloneDeep(asset);
      if (!item.additional) {
        // @ts-ignore
        item.additional = {};
      }
      if (!item.additional.settings) {
        // @ts-ignore
        item.additional.settings = {};
      }
      item.additional.settings.showAds = false;

      return initializePlayer(item, options);
    });

    return playerPromise;
  } catch (err) {
    console.error(err);
  }
};

export { initializePlayer, getPlayer, recreatePlayer };
